.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    width: 60px;  /* Ajusta el tamaño según sea necesario */
    height: 60px;
    background-color: green;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg');
    background-size: 60%;  /* Ajusta el tamaño del icono dentro del círculo */
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .whatsapp-icon a {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .whatsapp-icon:hover {
    background-color: darkgreen; /* Cambia el color de fondo al hacer hover */
  }
  
  
  
    